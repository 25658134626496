import { container, title } from 'assets/jss/material-kit-react.jsx';

const landingPageStyle = theme => {
  const {
    palette: { type, text, background, common },
  } = theme;
  return {
    pageHeader: {
      height: '100%',
      minHeight: 'calc(100vh - 70px)',
      display: 'flex',
      position: 'relative',
      margin: '0',
      padding: '0',
      border: '0',
      '&:before': {
        background: 'rgba(66, 133, 243, 0.6)',
      },
      '&:before,&:after': {
        position: 'absolute',
        zIndex: '0',
        width: '100%',
        height: '100%',
        display: 'block',
        left: '0',
        top: '0',
        content: '""',
      },
    },
    content: {
      fontSize: '1rem',
      textAlign: 'justify',
      '& a:link': {
        color: text.primary,
      },
      '& a:visited': {
        color: text.primary,
      },
    },
    container: {
      ...container,
      padding: 0,
      zIndex: '12',
      color: text.primary,
      justifyContent: 'center',
      textAlign: 'center',
    },
    title: {
      ...title,
      display: 'inline-block',
      position: 'relative',
      marginTop: '30px',
      minHeight: '32px',
      color: text.primary,
      textDecoration: 'none',
    },
    subtitle: {
      fontSize: '1.313rem',
      maxWidth: '500px',
      color: text.primary,
      margin: '10px auto 0',
    },
    main: {
      background: background.default,
      position: 'relative',
      zIndex: '3',
    },
    mainRaised: {
      margin: '-60px 0px 0px',
      paddingTop: '3px',
      paddingBottom: '5px',
      borderRadius: '0px',
      '@media (min-width: 576px)': {
        margin: '-50px 5px 0px',
        padding: '5px',
        borderRadius: '2px',
      },
      '@media (min-width: 768px)': {
        margin: '-50px 20px 0px',
        padding: '10px',
        borderRadius: '4px',
      },
      '@media (min-width: 992px)': {
        margin: '-50px 30px 0px',
        padding: '20px',
        borderRadius: '6px',
      },
      boxShadow:
        '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    },
    google: {
      backgroundColor: '#dd4b39',
      color: text.primary,
      boxShadow:
        '0 2px 2px 0 rgba(221, 75, 57, 0.14), 0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: '#dd4b39',
        color: text.primary,
        boxShadow:
          '0 14px 26px -12px rgba(221, 75, 57, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(221, 75, 57, 0.2)',
      },
    },
  };
};

export default landingPageStyle;
